import React from "react";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import App from "./features/app/App";
import Localization from "./features/localization/Localization";
import LocalizationList from "./features/localization/LocalizationList";
import UserList from "./features/user/UserList";
import GameWrapper from "./features/game/GameWrapper";
import NoFound from "./features/app/NoFound";
import Game from "./features/game/Game";
import Home from "./features/home/Home";
import Login from "./features/login/Login";

const Root = () => {
  
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<App />}>
          <Route path="login" element={<Login />} />

          <Route element={<GameWrapper />}>
            <Route path="home" element={<Home />} />
            <Route path="game" element={<Game />} />
            <Route path="game/:gameId" element={<Game />} />
            <Route path="game/:gameId/users" element={<UserList />} />
            <Route path="game/:gameId/localizations" element={<LocalizationList />} />
            <Route path="game/:gameId/localization/:localizationId" element={<Localization />} />
            <Route path="game/:gameId/localization" element={<Localization />} />
          </Route>
          <Route path="*" element={<NoFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Root;
