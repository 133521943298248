import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query";
import { AppStorageTypes } from "./store";

export const getAuthToken = (): string | null => localStorage.getItem(AppStorageTypes.TOKEN);
export const baseUrl = "https://gmzaplecze.zwik.pabianice.pl/";

export const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers) => {
    const token = getAuthToken();
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  },
});
