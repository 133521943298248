import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import AppInput from "../../common/components/AppInput";
import AppButton from "../../common/components/AppButton";
import { gameInitialValues, gameValidationSchema } from "./gameValidation";
import { get as _get } from "lodash";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAddGameMutation, useEditGameMutation, useLazyGetGameQuery } from "./gameApi";
import { AppStorageTypes } from "../../app/store";
import { GameInterface, StatusEnum } from "../../interfaces/game";

const Game = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { gameId } = useParams();
  const navigate = useNavigate();

  const [currentGame, setCurrentGame] = useState<GameInterface>();

  //@TODO HANDLE ERROR
  const [getGameById, { data: gameData, error: getGameError }] = useLazyGetGameQuery();
  const [addGame, { data: addGameData, error: addGameError, isLoading: addGameIsLoading }] = useAddGameMutation();
  const [editGame, { data: editGameData, error: editGameError, isLoading: editGameIsLoading }] = useEditGameMutation();

  const {
    register,
    reset,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: gameInitialValues,
    resolver: yupResolver(gameValidationSchema),
  });

  useEffect(() => {
    const gameId: string | null = localStorage.getItem(AppStorageTypes.GAME_ID);
    if (gameId) {
      navigate(`/game/${gameId}`);
    }
  }, []);

  // IF GAME ID IN ROUTE, GET THIS GAME
  useEffect(() => {
    if (gameId) {
      getGameById(gameId);

      const gameData: string | null = localStorage.getItem(AppStorageTypes.CURRENT_GAME);
      if (gameData) {
        const currentGame: GameInterface = JSON.parse(gameData);
        setCurrentGame(currentGame);
      }
    }
  }, [gameId]);

  console.log({ currentGame });

  // SET VALUES FROM API BY GAME ID
  useEffect(() => {
    if (gameData && gameData.document) {
      reset(gameData.document);
    }
  }, [gameData]);

  // IF ADD/EDIT IS SUCCESS, GO TO LOCATIONS
  useEffect(() => {
    if (addGameData && addGameData.documentId) {
      const currentGameId: number = addGameData.documentId;

      localStorage.setItem(AppStorageTypes.CURRENT_GAME, JSON.stringify(getValues()));
      localStorage.setItem(AppStorageTypes.GAME_ID, addGameData.documentId.toString());

      navigate(`/game/${currentGameId}/localizations`);
    }

    if (editGameData && editGameData.documentId) {
      const currentGameId: number = editGameData.documentId;
      localStorage.setItem(AppStorageTypes.CURRENT_GAME, JSON.stringify(getValues()));
      navigate(`/game/${currentGameId}/localizations`);
    }
  }, [addGameData, editGameData]);

  useEffect(() => {
    if (getGameError) {
      navigate("/home");
    }
  }, [getGameError]);

  // SET LOADING
  useEffect(() => {
    setIsLoading(addGameIsLoading || editGameIsLoading);
  }, [addGameIsLoading, editGameIsLoading]);

  // HANDLE GAME FORM SUBMIT FOR ADD/EDIT
  const onSubmit = handleSubmit((gameData: any): void => {
    if (gameId) {
      editGame({ gameId: gameId, data: gameData });
    } else {
      addGame(gameData);
    }
  });

  const getSaveButtonView = () => {
    if (!currentGame || (currentGame && currentGame.status === StatusEnum.NEW)) {
      return (
        <div className="button-wrapper">
          <AppButton isLoading={isLoading} type="submit" title={`${!gameId ? "Utwórz nową grę" : "zapisz grę"}`} />
        </div>
      );
    }

    return null;
  };

  const gameChangesDesabled = (): boolean | undefined => {
    return currentGame && currentGame.status !== StatusEnum.NEW;
  };

  return (
    <div className="game-form-wrapper">
      <p className="module-header">{!gameId ? "Dodaj nową grę" : "Edytuj aktualną grę"}</p>
      <Form className="game-form" onSubmit={onSubmit}>
        <AppInput
          register={register}
          placeholder="Podaj nazwę gry"
          label={"Nazwa gry"}
          name="name"
          disabled={gameChangesDesabled()}
          error={_get(errors, "name.message", null)}
        />
        <AppInput
          register={register}
          placeholder="Podaj liczbę kropli"
          label={"Limit kropli w grze"}
          name="dropCountLimit"
          type="number"
          disabled={gameChangesDesabled()}
          error={_get(errors, "dropCountLimit.message", null)}
        />
        <AppInput
          register={register}
          placeholder="Podaj liczbę kropli"
          label={"Ilość kropli wymaganych do zapisu"}
          name="dropCountRequired"
          type="number"
          disabled={gameChangesDesabled()}
          error={_get(errors, "dropCountRequired.message", null)}
        />
        <AppInput
          register={register}
          placeholder="Podaj liczbę godzin"
          label={"Ilość godzin do zapisu kropli"}
          name="hourCountToCollectDrops"
          type="number"
          disabled={gameChangesDesabled()}
          error={_get(errors, "hourCountToCollectDrops.message", null)}
        />
        {getSaveButtonView()}
      </Form>
    </div>
  );
};

export default Game;
